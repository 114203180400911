<template>
  <div>
    <van-nav-bar
        title="添加商户"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <van-form validate-first @failed="onFailed">
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
          v-model="accountUser.nickName"
          label="商户名"
          name="pattern"
          placeholder="商户名"
      />
      <!-- 通过 validator 进行函数校验 -->
      <van-field
          v-model="accountUser.phone"
          label="手机号码"
          name="validator"
          placeholder="手机号码"
      />

      <van-field
          v-model="accountUser.address"
          is-link
          readonly
          label="地址"
          placeholder="请选择所在地区"
          @click="selectAddressPopupShow = true"
      />
      <van-cell title="地图地址" :label="mapInfo.address" icon="award-o" is-link to="map" />
      <van-cell title="坐标" :label="coordinate" icon="location-o" />
      <van-popup v-model="selectAddressPopupShow" round position="bottom">
        <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="options"
            :field-names="fieldNames"
            @close="selectAddressPopupShow = false"
            @finish="onFinish"
            @change="onAreaChange"
        />
      </van-popup>
      <!--      <van-field name="uploader" label="头像">-->
      <!--        <template #input>-->
      <!--          <van-uploader v-model="accountUser.avatar" />-->
      <!--        </template>-->
      <!--      </van-field>-->
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" @click="onSubmit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Toast,
  Form,
  Button,
  Field,
  Uploader,
  NavBar,
  Cascader,
  Popup, Dialog, Notify
} from 'vant';
import $store from "@/store";
import * as getDataService from "@/api/serverData/getServerData";
import * as service from "@/api/admin/login";
import {mapState} from "vuex";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [NavBar.name]: NavBar,
    [Cascader.name]: Cascader,
    [Popup.name]: Popup,
    [Notify.name]: Notify,
  },
  data() {
    return {
      // accountUser: {},
      pattern: /\d{6}/,
      uploader: [{url: 'https://img01.yzcdn.cn/vant/leaf.jpg'}],

      selectAddressPopupShow: false,
      cascaderValue: '',
      options: [
        {
          text: '浙江省',
          value: '330000',
          children: [{text: '杭州市', value: '330100'}],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [{text: '南京市', value: '320100'}],
        },
      ],
      fieldNames: {
        text: 'n',
        value: 'v',
        children: 'c',
      },
    };
  },
  computed: {
    ...mapState({
      mapInfo: state => state.map.mapInfo,
    }),
    accountUser: {
      get () {
        return $store.state.user.accountUser
      },
      set (value) {
        $store.commit('SET_ACCOUNT_USER_ADD', value)
      }
    },
    coordinate: function () {
      if ($store.state.map.mapInfo.lat !== undefined) {
        return $store.state.map.mapInfo.lat+ ',' + $store.state.map.mapInfo.lng
      }
      return null
    },
  },
  created() {
    getDataService.getCity().then(res => {
      if (res.status === 200) {
        this.options = res.data
      }
    });
  },
  methods: {
    onSubmit() {
      if (this.accountUser.address === undefined) {
        this.accountUser.address = '西藏自治区 拉萨市'
      }
      if (this.accountUser.phone === undefined) {
        this.accountUser.phone = '13648983064'
      }

      if (this.accountUser.nickName === undefined) {
        Toast("请输入商户名")
        return
      }

      Dialog.confirm({
        title: '确认添加此用户吗？',
        message: '请仔细检查～',
      })
      .then(() => {
        this.accountUser.username = this.accountUser.nickName;
        this.accountUser.avatar = 'https://img01.yzcdn.cn/vant/cat.jpeg'
        this.accountUser.deliveryAddress = this.accountUser.address
        this.accountUser.coordinate = this.coordinate
        console.log(this.accountUser)
        service.addUser(this.accountUser).then(res => {
          Notify({type: 'success', message: '新增成功'});
          $store.commit('SET_ACCOUNT_USER_ADD', {})
          $store.commit('SET_MAP_INFO', {})
        })
      })
      .catch(() => {
        // on cancel
      });
    },
    onClickLeft() {
      this.$router.push({path: '/order'})
    },
    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator(val) {
      return /1\d{10}/.test(val);
    },
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        Toast.loading('验证中...');

        setTimeout(() => {
          Toast.clear();
          resolve(/\d{6}/.test(val));
        }, 1000);
      });
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({selectedOptions}) {
      this.selectAddressPopupShow = false;
      this.accountUser.address = selectedOptions.map((option) => option.n).join(' ');
    },
    onAreaChange({selectedOptions}) {
      this.accountUser.address = selectedOptions.map((option) => option.n).join(' ');
    }
  },
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
